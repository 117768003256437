<template>
    <div class="content">
        <header class="section is-fullwidth my-2">
            <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ $t("navigation.pricing") }}</h1>
            <p class="is-size-4-desktop is-size-5-tablet mb-6">
                {{ $t("message.pricing.pricing") }}
            </p>
        </header>
        
        <main class="section is-fullwidth my-2">
            <div class="pricing-table">
                <div class="pricing-plan is-primary">
                    <div class="plan-header">{{ $t("message.pricing.free_trial") }}</div>
                    <div class="plan-price">
                        <span class="plan-price-amount"><span class="plan-price-currency"></span>{{ $t("message.pricing.free") }}</span>
                    </div>
                    <div class="plan-items">
                        <div class="plan-item">{{ $t("message.pricing.storage", {size: 50}) }}</div>
                        <div class="plan-item">{{ $t("message.pricing.unlimited") }}</div>
                    </div>
                    <div class="plan-footer">
                        <!--<button class="button is-fullwidth">{{ $t("message.pricing.try") }}</button>-->
                        <span class="tag is-seenjab-green mr-2">{{ $t("message.common.available_soon") }}</span>
                    </div>
                </div>

                <div class="pricing-plan is-active">
                    <div class="plan-header">{{ $t("message.pricing.premium", {months: 1}) }}</div>
                    <div class="plan-price">
                        <span class="plan-price-amount"><span class="plan-price-currency">CHF</span>10</span>/{{ $t("message.pricing.month") }}
                    </div>
                    <div class="plan-items">
                        <div class="plan-item">{{ $t("message.pricing.storage", {size: 15000}) }}</div>
                        <div class="plan-item">{{ $t("message.pricing.deadlines") }}</div>
                        <div class="plan-item">{{ $t("message.pricing.notifications") }}</div>
                        <div class="plan-item">{{ $t("message.pricing.auto_renewal") }}</div>
                    </div>
                    <div class="plan-footer">
                        <!--<button class="button is-fullwidth">{{ $t("message.pricing.order") }}</button>-->
                        <span class="tag is-seenjab-green mr-2">{{ $t("message.common.available_soon") }}</span>
                    </div>
                </div>

                <!--<div class="pricing-plan is-primary">
                    <div class="plan-header">{{ $t("message.pricing.premium", {months: 12, s: 's'}) }}</div>
                    <div class="plan-price">
                        <span class="plan-price-amount"><span class="plan-price-currency">CHF</span>100</span>
                    </div>
                    <div class="plan-items">
                        <div class="plan-item">{{ $t("message.pricing.storage", {size: 500}) }}</div>
                        <div class="plan-item">{{ $t("message.pricing.deadlines") }}</div>
                        <div class="plan-item">{{ $t("message.pricing.notifications") }}</div>
                        <div class="plan-item">{{ $t("message.pricing.discount", {discount: 16.33}) }}</div>
                    </div>
                    <div class="plan-footer">
                        <button class="button is-fullwidth">{{ $t("message.pricing.order") }}</button>
                    </div>
                </div>-->
            </div>
            <p class="is-size-5-desktop is-size-6-tablet mt-6" v-html="$t('message.pricing.non_profit', {locale: $store.getters['user/getLocale'], contact: $t('navigation.contact_url')})"></p>
        </main>
    </div>
</template>

<script>
/**
 * Pricing view
 * Displays the Pricing page
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'Pricing',
    metaInfo: function() {
        return {
            title: `${this.$t("message.pricing.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.pricing.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'og:title',
                    content: `${this.$t("message.pricing.meta.title")} | ${this.$t('message.title')}`
                },
                {
                    name: 'og:image',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:secure_url',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:type',
                    content: 'image/png'
                },
                {
                    name: 'og:image:alt',
                    content: 'Seenjab logo small'
                },
                {
                    name: 'og:description',
                    content: this.$t('message.home.pricing.description'),
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';
.pricing-table {
    justify-content: center;
}
.pricing-plan {
    width: 25%;
    flex-grow: 0;
}

@media(max-width: $tablet-landscape) {
    .pricing-table {
        flex-direction: column;
    }
    .pricing-plan {
        width: 95%;
    }
}
</style>